<template>
  <div class="container">
    <van-tabs v-model="active" @change="changeTab">
      <van-tab :title="item" v-for="(item, index) in tab" :key="index">
        <div v-if="orderList.length != 0">
          <div
            class="item"
            v-for="item1 in orderList"
            :key="item1.orderNo"
            @click="orderDetail(item1)"
          >
            <div class="orderNo">
              <p class="num">订单号:{{ item1.orderNo }}</p>
              <p class="status">{{ item1.orderStatusDisplay }}</p>
            </div>
            <div class="detail">
              <img :src="item1.productThumbnail" alt="" />
              <div class="right">
                <p class="name">{{ item1.productName }}</p>
                <p class="date">下单时间:{{ item1.makeOrderTime }}</p>
                <p class="price">
                  支付金额<span>￥</span>{{ item1.orderPrice }}
                </p>
              </div>
            </div>
            <div class="time" v-if="item1.orderStatus == 1">
              <p>
                <span>{{ item1.mm }}</span
                >分<span>{{ item1.ss }}</span
                >秒后自动取消
              </p>
            </div>
            <div class="btns">
              <p
                class="cancel"
                v-if="item1.orderStatus == 1"
                @click.stop="cancel(item1)"
              >
                取消订单
              </p>
              <p
                class="pay"
                v-if="item1.orderStatus == 1"
                @click.stop="payOrderWxPub(item1)"
              >
                立即支付
              </p>
            </div>
          </div>
        </div>
        <div class="defult" v-else>
          <img
            src="https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/142628.png"
            alt=""
          />
          <p>暂无数据</p>
        </div>
      </van-tab>

      <!-- <van-tab title="待支付"></van-tab>
      <van-tab title="已支付"></van-tab>
      <van-tab title="已取消"></van-tab> -->
    </van-tabs>
  </div>
</template>
<script>
import { getOrderList, cancelOrder, payOrderWxPub } from "../utils/api";
export default {
  data() {
    return {
      active: 0,
      orderList: [],
      newList: [],
      tabVal: 0,
      tab: ["全部", "待支付", "已支付", "已取消"],
    };
  },

  mounted() {
    document.title = "我的订单";
    this.getOrderList();
  },
  methods: {
    //去支付
    payOrderWxPub(item) {
      let params = {
        openId: sessionStorage.getItem("openid"),
        channelId: "wx20508d66c240b36a",
        orderNo: item.orderNo,
      };
      payOrderWxPub(params).then((res) => {
        let xmIssueParams = {
          feeAmount: item.orderPrice * 100,
          orderNo: item.orderNo,
          proposalNumber: item.proposalNumber,
        };
        localStorage.setItem("xmIssueParams", JSON.stringify(xmIssueParams));
        window.location.href = res.result;
      });
    },
    //点击取消订单
    cancel(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认取消该订单？",
        })
        .then(() => {
          // on confirm
          this.cancelOrder(item);
        })
        .catch(() => {
          // on cancel
        });
    },
    //订单详情
    orderDetail(item1) {
      this.$router.push({
        path: "/orderDetail",
        query: {
          orderNo: item1.orderNo,
        },
      });
    },
    changeTab(val) {
      console.log(val);
      this.tabVal = val;
      if (val == 0) {
        this.getOrderList();
      } else if (val == 1) {
        this.getOrderList(1);
      } else if (val == 2) {
        this.getOrderList(2);
      } else if (val == 3) {
        this.getOrderList(11);
      }
    },
    //订单列表
    async getOrderList(status) {
      this.newList = [];
      let params = {
        openId: sessionStorage.getItem("openid"),
      };
      if (status) {
        params.orderStatus = status;
      }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getOrderList(params).then((res) => {
        this.$toast.clear();
        if (res.result) {
          this.orderList = res.result;
          this.orderList.map((item) => {
            if (item.orderStatus === 1) {
              this.newList.push(item);
            }
          });
          if (this.newList.length !== 0) {
            this.newList.map((item, index) => {
              this.$set(item, "ss", this.getTimeList(index, item, 15));
            });
          }
        }else{
          this.orderList = [];
        }
      });
      
    },

    //取消订单
    async cancelOrder(item) {
      let params = {
        orderNo: item.orderNo,
        proposalNumber: item.proposalNumber,
      };
      await cancelOrder(params).then((res) => {
        if (this.tabVal == 0) {
          this.getOrderList();
        } else if (this.tabVal == 1) {
          this.getOrderList(1);
        } else if (this.tabVal == 2) {
          this.getOrderList(2);
        } else if (this.tabVal == 3) {
          this.getOrderList(11);
        }
        this.$toast.success("取消成功");
      });
    },
    getTimeList(index, item) {
      let _this = this;
      var interval = setInterval(async function () {
        let a = item.makeOrderTime;
        const b = a.replace(/-/g, "/");
        let ti = new Date(b).getTime();
        var start = new Date().getTime();
        var end = new Date(ti + 1 * 60 * 60 * 1000).getTime();
        var rightTime = end - start;
        if (rightTime > 0) {
          var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
          var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
          var mm = Math.floor((rightTime / 1000 / 60) % 60);
          var ss = Math.floor((rightTime / 1000) % 60);
          dd = dd > 9 ? dd : "0" + dd;
          hh = hh > 9 ? hh : "0" + hh;
          mm = mm > 9 ? mm : "0" + mm;
          ss = ss > 9 ? ss : "0" + ss;
          _this.newList[index]["dd"] = dd;
          _this.newList[index]["hh"] = hh;
          _this.newList[index]["mm"] = mm;
          _this.newList[index]["ss"] = ss;
        } else {
          _this.newList[index]["dd"] = 0;
          _this.newList[index]["hh"] = 0;
          _this.newList[index]["mm"] = 0;
          _this.newList[index]["ss"] = 0;
          clearInterval(interval);
          if (_this.tabVal == 0) {
            _this.getOrderList();
          } else if (_this.tabVal == 1) {
            _this.getOrderList(1);
          } else if (_this.tabVal == 2) {
            _this.getOrderList(2);
          } else if (_this.tabVal == 3) {
            _this.getOrderList(11);
          }
          // await _this.cancelOrder(item);
        }
      }, 1000);
    },
  },
};
</script>
 
<style lang="scss" scoped>
/deep/ .van-tabs__line {
  // bottom: 3rem;
}
/deep/ .van-tabs__content {
  padding: 12px 10px 0;
}
/deep/ .van-tabs__line {
  background-color: $primary-color;
}
.container {
  width: 100%;
  height: 100vh;
  background: #f7f7f7;
  .item {
    padding: 12px 10px 15px;
    background: #ffffff;
    border-radius: 3px;
    margin-bottom: 15px;
    .orderNo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
      }
      .status {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }
    }
    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 14px;
      img {
        width: 80px;
        height: 80px !important;
      }
      .right {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 75px;
        margin-left: 10px;
        .name {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .date {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
        }
        .price {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          span {
            font-size: 10px;
            padding-left: 4px;
          }
        }
      }
    }
    .time {
      width: 100%;
      display: flex;
      margin-top: 16px;
      p {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-align: right;
        span {
          color: $primary-color;
        }
      }
    }
    .btns {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
      p {
        width: 80px;
        height: 30px;
        border-radius: 15px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      .cancel {
        border: 1px solid #d0d0d0;
        color: #666666;
      }
      .pay {
        background-color: $primary-color;
        color: #ffffff;
        border: 1px solid $primary-color;
      }
    }
  }
  .defult {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    img {
      width: 200px;
    }
    p {
      font-size: 14px;
      line-height: 14px;
      color: #666;
      margin-top: 20px;
    }
  }
}
</style>